import React from "react"
import IntroBox from "../../components/intro-box"
import BasicLayout from "../../layouts/basic-layout"
import styled from "styled-components"

const desc =
  "梦居度假酒店位于抚仙湖畔，抚仙湖是我国有名的淡水湖，这里的水质极佳，湖水清澈见底，是我国第二深水湖泊。这里有帽天山国家地质公园，因发现纳罗虫化石群二闻名，埋藏着距今5.41亿年前的动物化石群。梦居度假酒店坐拥抚仙湖180度湖光山色，独揽迷人的白色沙滩和湿地公园。晨观日出，暮赏夕阳，是完美的休憩之所，也是重新找回健康生活方式及生活乐趣的理想之地。酒店距长水国际机场车程约50分钟，距昆明约1小时。"

const Gallery = styled.div`
  width: 100%;
  padding: 30px 0;
  margin-top: 30px;
  background-color: #eee;
  /* min-height: 30rem; */

  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    width: 31%;
    height: 100%;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
    max-height: 30rem;
  }
`

const ShowBox = styled.div`
  background: url("http://maps.yncce.cn/website/jdyy-gl.png") no-repeat center;
  background-size: contain;
  height: 600px;
  margin: 30px auto;
  max-width: 1200px;

  @media screen and (max-width: 768px) {
    height: 300px;
  }
`

const Page = () => (
  <BasicLayout
    title="酒店运营"
    width="100%"
    bannerImg="http://maps.yncce.cn/website/jdyy.png"
  >
    <IntroBox
      width="1200px"
      height="20rem"
      desc={desc}
      img="http://cdn.jerryshi.com/gtkj/20200108114106.jpg"
    />
    <Gallery>
      <div className="wrapper">
        <img src="http://maps.yncce.cn/website/jdyy-g1.png" alt="1" />
        <img src="http://maps.yncce.cn/website/jdyy-g2.png" alt="2" />
        <img src="http://maps.yncce.cn/website/jdyy-g3.png" alt="3" />
      </div>
    </Gallery>
    <ShowBox />
  </BasicLayout>
)

export default Page
